import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useProfileSearch() {
  // Use toast
  const toast = useToast();

  const refProfileListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', stickyColumn: true, variant: 'primary' },
    // {key: 'rep'},
    { key: 'pathway', sortable: true },
    { key: 'session', sortable: true },
    { key: 'resume' },
    { key: 'coe' },
    { key: 'submission' },
    { key: 'funding' },
    { key: 'createdAt', label: 'Created', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalProfiles = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('createdAt');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref('graduated');
  const includeEnrolledFilter = ref('no');
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);
  const graduationAtStartFilter = ref(null);
  const graduationAtEndFilter = ref(null);
  const pathwayFilter = ref(null);
  const subjectFilter = ref(null);
  const fundingFilter = ref(null);
  const resumeFilter = ref(null);
  const coeFilter = ref(null);
  const submissionFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProfileListTable.value ? refProfileListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProfiles.value,
    };
  });

  const refetchData = () => {
    refProfileListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      includeEnrolledFilter,
      statusFilter,
      createAtStartFilter,
      createAtEndFilter,
      graduationAtStartFilter,
      graduationAtEndFilter,
      pathwayFilter,
      fundingFilter,
      resumeFilter,
      coeFilter,
      submissionFilter,
      subjectFilter,
    ],
    () => {
      refetchData();
    }
  );

  const filterPayload = () => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'student,session,pathway,representative',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }
    // if (includeEnrolledFilter.value === 'no') {
    //   pl.session = 'null'
    // }
    if (createAtStartFilter.value) {
      pl.createAtStart = createAtStartFilter.value;
    }
    if (createAtEndFilter.value) {
      pl.createAtEnd = createAtEndFilter.value;
    }

    if (graduationAtStartFilter.value) {
      pl.graduationAtStart = graduationAtStartFilter.value;
    }
    if (graduationAtEndFilter.value) {
      pl.graduationAtEnd = graduationAtEndFilter.value;
    }
    if (pathwayFilter.value) {
      pl.pathway = pathwayFilter.value;
    }
    if (subjectFilter.value) {
      pl.subject = subjectFilter.value;
    }

    if (fundingFilter.value) {
      pl.fundingSource = fundingFilter.value;
    }

    if (resumeFilter.value) {
      pl.uploadedResume = resumeFilter.value;
    }
    if (coeFilter.value) {
      pl.uploadedCoe = coeFilter.value;
    }
    if (submissionFilter.value) {
      pl.submittedToVa = submissionFilter.value;
    }

    return pl;
  };

  const fetchProfiles = (ctx, callback) => {
    console.log(`searching`);
    // if (!subjectFilter.value) return;

    /*
    resumeFilter,
    coeFilter,
    submissionFilter, */

    const pl = filterPayload();

    store
      .dispatch('app-roster/searchProfiles', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalProfiles.value = totalResults;
      })
      .catch((error) => {
        if (error.response) {
          const { response } = error;

          if (response.status === 404) {
            callback([]);
            totalProfiles.value = 0;
            return;
          }
        }

        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching profiles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'subscriber') return 'primary';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'subscriber') return 'ProfileIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'ProfileIcon';
  };

  const resolveProfileVisibilityVariant = (status) => {
    if (status === 'internal') return 'primary';
    if (status === 'public') return 'info';
    return 'primary';
  };

  const resolveProfileStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'pending') return 'danger';
    if (status === 'action_required') return 'primary';
    if (status === 'enrolled') return 'info';
    if (status === 'withdrew') return 'warning';
    if (status === 'terminated') return 'danger';
    if (status === 'graduated') return 'success';
    return 'primary';
  };

  const resolveProfileProgramVariant = (status) => {
    if (status === 'open') return 'success';
    if (status === 'vettec') return 'info';
    if (status === 'vrrap') return 'primary';
    if (status === 'wioa') return 'warning';
    return 'secondary';
  };

  const resolveProfileYNVariant = (status) => {
    if (status === 'yes') return 'success';
    if (status === 'no') return 'danger';
    return 'warning';
  };

  const resolveProfilePaymentStatusVariant = (status) => {
    if (status === 'unpaid') return 'danger';
    if (status === 'partially_paid') return 'warning';
    if (status === 'payment_plan') return 'info';
    if (status === 'paid') return 'success';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Pending', value: 'pending' },
    { label: 'Action Required', value: 'action_required' },
    { label: 'Enrolled', value: 'enrolled' },
    { label: 'Withdrawal (before program start)', value: 'withdrew_s1' },
    { label: 'Withdrawal (during withdrawal period)', value: 'withdrew_s2' },
    { label: 'Withdrawal (after withdrawal period)', value: 'withdrew_s3' },
    { label: 'Terminated', value: 'terminated' },
    { label: 'Graduated', value: 'graduated' },
    { label: 'Denial of Benefits', value: 'denial_of_benefit' },
    { label: 'Archived', value: 'archived' },
  ];

  const courseStatusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Enrolled', value: 'enrolled' },
    { label: 'Completed', value: 'completed' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Student', value: 'student' },
  ];

  const fundingSourceOptions = [
    { label: 'Open', value: 'open' },
    { label: 'VRRAP', value: 'vrrap' },
    { label: 'VET-TEC', value: 'vettec' },
    { label: 'WIOA', value: 'wioa' },
    { label: 'GI Bill', value: 'gi_bill' },
    { label: 'Army COOL', value: 'army_cool' },
    { label: 'AirForce COOL', value: 'airforce_cool' },
    { label: 'MYCAA', value: 'mycaa' },
    { label: 'VR&E', value: 'vrne' },
    { label: 'Corporate', value: 'corp' },
  ];

  const paymentStatusOptions = [
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Partially Paid', value: 'partially_paid' },
    { label: 'Payment Plan', value: 'payment_plan' },
    { label: 'Paid', value: 'paid' },
  ];

  const submittedToVaOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const uploadedCoeOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const uploadedResumeOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const yesNoOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const locationOptions = [
    { label: 'Virtual', value: 'virtual' },
    { label: 'Physical', value: 'physical' },
  ];

  return {
    fetchProfiles,
    tableColumns,
    perPage,
    currentPage,
    totalProfiles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProfileListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveProfileVisibilityVariant,
    resolveProfileStatusVariant,

    resolveProfileProgramVariant,
    resolveProfileYNVariant,
    resolveProfilePaymentStatusVariant,

    refetchData,

    // Extra Filters
    visibilityFilter,
    statusFilter,
    includeEnrolledFilter,
    createAtStartFilter,
    createAtEndFilter,
    graduationAtStartFilter,
    graduationAtEndFilter,
    pathwayFilter,
    fundingFilter,

    resumeFilter,
    coeFilter,
    submissionFilter,
    subjectFilter,

    //

    filterPayload,

    //

    statusOptions,
    visibilityOptions,
    fundingSourceOptions,
    paymentStatusOptions,
    submittedToVaOptions,
    uploadedCoeOptions,
    uploadedResumeOptions,
    yesNoOptions,
    courseStatusOptions,
    locationOptions,
  };
}
