<template>
  <div>
    <b-card-actions title="Filters" action-collapse>
      <b-card-text>
        <b-row>
          <b-col cols="12" class="mb-1">
            <label>Pathway</label>

            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="pathwayFilter"
              :options="pathwaySuggestions"
              class="w-100"
              :reduce="(val) => val.value"
              @search="searchForPathway"
              @input="(val) => $emit('update:pathwayFilter', val)"
            />
          </b-col>
          <b-col cols="12" class="mb-1">
            <label>Subject</label>

            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="subjectFilter"
              :options="subjectSuggestions"
              class="w-100"
              :reduce="(val) => val.value"
              @search="searchForSubject"
              @input="(val) => $emit('update:subjectFilter', val)"
            />
          </b-col>

          <b-col cols="12" class="mb-1">
            <label for="report-data-date-range">Date of Completion [start - end]</label>

            <flat-pickr
              id="report-data-date-range"
              class="w-100 form-control"
              :config="{ mode: 'range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
              @input="(val) => updateDateFilter(val)"
              v-model="dateRange"
            />
          </b-col>

          <b-col cols="12" class="mb-1">
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-actions>

    <b-card-actions title="Attributes" action-collapse collapsed>
      <b-card-text>
        <b-row>
          <b-col cols="12" class="mb-1">
            <label>Funding Source</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="fundingFilter"
              :options="fundingOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:fundingFilter', val)"
            />
          </b-col>

          <b-col cols="12" class="mb-1">
            <label>Resume</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="resumeFilter"
              :options="resumeOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:resumeFilter', val)"
            />
          </b-col>

          <b-col cols="12" class="mb-1">
            <label>COE</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="coeFilter"
              :options="coeOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:coeFilter', val)"
            />
          </b-col>

          <b-col cols="12" class="mb-1">
            <label>VA Submission</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="submissionFilter"
              :options="submissionOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:submissionFilter', val)"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-actions>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText, BFormDatepicker } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import { onUnmounted, ref } from '@vue/composition-api';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BCardActions,
    BCardText,
    vSelect,
    flatPickr,
  },
  data: () => {
    return {
      includeEnrolledOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      dateRange: '',
    };
  },
  methods: {
    updateDateFilter(inp) {
      const [start, end] = inp.split(' to ');
      this.$emit('update:graduationAtStartFilter', start);
      this.$emit('update:graduationAtEndFilter', end);
    },
    pathwayFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const pathwaysPromise = this.$store.dispatch('app-placement/fetchCohorts', {
          search: this.pathwayQuery,
        });

        Promise.all([pathwaysPromise]).then((values) => {
          this.pathwaySuggestions = [];

          const pathways = values[0].data.results;

          pathways.length && this.pathwaySuggestions.push({ name: 'pathways', data: pathways });
        });
      }, 250);
    },
    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchPathways', { search });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: id, label: name };
      });
    },

    async searchForSubject(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchSubjects', { search });
      const subjects = response.data.results;

      this.subjectSuggestions = subjects.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },
  },
  props: {
    includeEnrolledFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    createAtStartFilter: {
      type: [String, null],
      default: null,
    },
    createAtEndFilter: {
      type: [String, null],
      default: null,
    },
    graduationAtStartFilter: {
      type: [String, null],
      default: null,
    },
    graduationAtEndFilter: {
      type: [String, null],
      default: null,
    },
    pathwayFilter: {
      type: [String, null],
      default: null,
    },
    fundingFilter: { type: [String, null], default: null },
    fundingOptions: { type: Array, required: true },
    resumeFilter: { type: [String, null], default: null },
    resumeOptions: { type: Array, required: true },
    coeFilter: { type: [String, null], default: null },
    coeOptions: { type: Array, required: true },
    submissionFilter: { type: [String, null], default: null },
    submissionOptions: { type: Array, required: true },
    subjectFilter: { type: [String, null], default: null },
  },
  mounted() {
    this.searchForPathway('');
    this.searchForSubject('');
  },
  setup(props, { emit }) {
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, placementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);
    const pathwaySelected = ref(undefined);
    const pathwaySectionConfigs = {
      pathways: {
        limit: 10,
        label: 'Pathways',
        onSelected: (selected) => {
          pathwaySelected.value = selected.item;
          emit('update:pathwayFilter', selected.item);
        },
      },
    };
    const pathwayInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for pathways',
      class: 'form-control',
      name: 'ajax',
    };

    const subjectQuery = ref('');
    const subjectSuggestions = ref([]);
    const subjectSelected = ref(undefined);
    const subjectSectionConfigs = {
      subjects: {
        limit: 10,
        label: 'Subjects',
        onSelected: (selected) => {
          subjectSelected.value = selected.item;
          emit('update:subjectFilter', selected.item);
        },
      },
    };
    const subjectInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for subjects',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      pathwayQuery,
      pathwaySuggestions,
      pathwaySectionConfigs,
      pathwayInputProps,
      pathwaySelected,

      subjectQuery,
      subjectSuggestions,
      subjectSectionConfigs,
      subjectInputProps,
      subjectSelected,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
