import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function usePathwaysList() {
  // Use toast
  const toast = useToast();

  const refPathwayListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    {
      key: 'sessions',
      label: 'Courses',
      formatter: (value) => {
        if (value.length === 0) return 'No sessions assigned';

        const listOfCourses = value
          .map((session) => session.courses.map((course) => course.name.trim()))
          .reduce((session) => session)
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .join(', ');

        return listOfCourses;
      },
    },
    {
      key: 'live_sessions',
      label: 'Live',
    },
    {
      key: 'complete_sessions',
      label: 'Completed',
    },
    // {
    //   key: 'createdAt',
    //   label: 'Created At',
    //   formatter: value => {
    //     return moment(value).format('L');
    //   },
    //   sortable: true,
    // },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalPathways = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refPathwayListTable.value ? refPathwayListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPathways.value,
    };
  });

  const refetchData = () => {
    refPathwayListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter], () => {
    refetchData();
  });

  const fetchPathways = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'sessions,sessions.courses',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('app-roster/fetchPathways', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalPathways.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching pathways list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'subscriber') return 'primary';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'subscriber') return 'PathwayIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'PathwayIcon';
  };

  const resolvePathwayVisibilityVariant = (status) => {
    if (status === 'internal') return 'primary';
    if (status === 'public') return 'info';
    return 'primary';
  };

  const resolvePathwayStatusVariant = (status) => {
    if (status === 'created') return 'info';
    if (status === 'pending_approval') return 'primary';
    if (status === 'approved') return 'warning';
    if (status === 'live') return 'success';
    if (status === 'retired') return 'secondary';
    return 'primary';
  };

  return {
    fetchPathways,
    tableColumns,
    perPage,
    currentPage,
    totalPathways,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPathwayListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolvePathwayVisibilityVariant,
    resolvePathwayStatusVariant,
    refetchData,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
